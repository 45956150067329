import React from 'react'
import Layout from '../components/layout'
import ServicesData from '../content/services_data.json'
import Service from '../components/Service'
import vehicles from '../assets/images/vehicles.jpg'

class Vehicles extends React.Component {

  render () {
    return (
      <Layout>
          <Service 
            name={ "Trámites para vehículos" } 
            description={ "Los trámites para vehículos son todos aquellos que tienen un procedimiento relacionado directamente con el vehículo y no con el propietario. Para todos los trámites de vehículos se debe tener: seguro obligatorio, revisión técnico mecánica, estar inscrito al RUNT, estar a paz y salvo con sus impuestos y no tener comparendos. Exceptuando los siguientes trámites en donde no se requiere de lo anterior: Certificado de tradición, toma de improntas, venta de seguro obligatorio (SOAT), pago de impuestos, duplicado de placa (únicamente para personas que deseen sacar el duplicado directamente desde fábrica)." } 
            img={ vehicles }
            service_data={ ServicesData.vehicles } />
      </Layout>
    )
  }
}

export default Vehicles